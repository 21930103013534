import React from 'react';
import './App.scss';
import {AppBar, Button, Input, Paper, Stack, Toolbar} from "@mui/material";
import {Box} from "@mui/system";

type PieceColor = 'White' | 'Black';
type PieceChar = '♕' | '♔' | '♖' | '♗' | '♘' | '♙' | '♛' | '♚' | '♜' | '♝' | '♞' | '♟';
type PieceName = 'Queen' | 'King' | 'Rook' | 'Bishop' | 'Knight' | 'Pawn';

class Piece {
    color: PieceColor;
    char: PieceChar;
    name: PieceName;

    constructor({color, char, name}: { color: PieceColor, char: PieceChar, name: PieceName }) {
        this.color = color;
        this.char = char;
        this.name = name;
    }
}

let pieces = {
    WhiteQueen: new Piece({color: 'White', char: '♕', name: 'Queen'}),
    WhiteKing: new Piece({color: 'White', char: '♔', name: 'King'}),
    WhiteRook: new Piece({color: 'White', char: '♖', name: 'Rook'}),
    WhiteBishop: new Piece({color: 'White', char: '♗', name: 'Bishop'}),
    WhiteKnight: new Piece({color: 'White', char: '♘', name: 'Knight'}),
    WhitePawn: new Piece({color: 'White', char: '♙', name: 'Pawn'}),
    BlackQueen: new Piece({color: 'Black', char: '♛', name: 'Queen'}),
    BlackKing: new Piece({color: 'Black', char: '♚', name: 'King'}),
    BlackRook: new Piece({color: 'Black', char: '♜', name: 'Rook'}),
    BlackBishop: new Piece({color: 'Black', char: '♝', name: 'Bishop'}),
    BlackKnight: new Piece({color: 'Black', char: '♞', name: 'Knight'}),
    BlackPawn: new Piece({color: 'Black', char: '♟', name: 'Pawn'}),
};

function focusMove() {
    const element: HTMLElement | null = document.getElementById('move');
    element?.focus();
}

const initialBoard = [
    [pieces.WhiteRook, pieces.WhiteKnight, pieces.WhiteBishop, pieces.WhiteQueen, pieces.WhiteKing, pieces.WhiteBishop, pieces.WhiteKnight, pieces.WhiteRook],
    [pieces.WhitePawn, pieces.WhitePawn, pieces.WhitePawn, pieces.WhitePawn, pieces.WhitePawn, pieces.WhitePawn, pieces.WhitePawn, pieces.WhitePawn],
    [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    [pieces.BlackPawn, pieces.BlackPawn, pieces.BlackPawn, pieces.BlackPawn, pieces.BlackPawn, pieces.BlackPawn, pieces.BlackPawn, pieces.BlackPawn],
    [pieces.BlackRook, pieces.BlackKnight, pieces.BlackBishop, pieces.BlackQueen, pieces.BlackKing, pieces.BlackBishop, pieces.BlackKnight, pieces.BlackRook],
];

const isLightCell = (rowIndex: number, colIndex: number) => (rowIndex + colIndex) % 2 === 1;
const cellKey = (rowIndex: number, colIndex: number) => `${rowIndex}:${colIndex}`;
const darkCellBackgroundColor = "rgba(38,36,36,0.24)";
const lightCellBackgroundColor = "rgba(230, 233, 220, 0.9)";


function App() {
    const [cells, setCells] = React.useState<Array<Piece | undefined>[]>(initialBoard);

    const [ws, setWs] = React.useState<WebSocket | undefined>(undefined);

    const [move, setMove] = React.useState<string>('');

    const connect = () => {
        let url = window.location.host.indexOf("localhost") !== -1 ? "ws://localhost:8080/ws" : `wss://${window.location.host}/api-main/ws`;
        const ws = new WebSocket(url);
        ws.onopen = () => {
            ws.send("hello");
            focusMove();
        };
        ws.onmessage = function (event) {
            console.log('Response: ' + event.data);
        };
        ws.onclose = function () {
            console.log("WebSocket is closed now.");
        }
        ws.onerror = function (event) {
            console.error("WebSocket error observed:", event);
        }
        setWs(ws);
    };

    const disconnect = () => {
        ws?.close();
        setWs(undefined)
    }

    const performMove = () => {
        try {
            if (!move.match(/^[qnkb]?[a-h][1-8]$/)) {
                console.error(`Invalid move: ${move}`);
                setMove('');
                return;
            }
            let piece: Piece;
            if (move.length === 2) {
                piece = pieces.WhitePawn;
            } else {
                switch (move[0]) {
                    case 'r':
                        piece = pieces.WhiteRook;
                        break;
                    case 'n':
                        piece = pieces.WhiteKnight;
                        break;
                    case 'b':
                        piece = pieces.WhiteBishop;
                        break;
                    case 'q':
                        piece = pieces.WhiteQueen;
                        break;
                    default:
                        console.error(`Invalid move: ${move}`);
                        setMove('');
                        return;
                }
            }

            let pieceLocation: { row: number, column: number } | undefined = undefined;

            //find the destination of the piece
            let destination: { row: number, column: number };
            if (move.length === 2) { //pawn
                destination = {row: parseInt(move[1]) - 1, column: move.charCodeAt(0) - 97};
                if (cells[destination.row - 1][destination.column] === pieces.WhitePawn) {
                    pieceLocation = {row: destination.row - 1, column: destination.column};
                } else if (cells[destination.row - 2][destination.column] === pieces.WhitePawn) {
                    pieceLocation = {row: destination.row - 2, column: destination.column};
                } else {
                    for (let row = 0; row < cells.length; row++) {
                        for (let column = 0; column < cells[row].length; column++) {
                            if (cells[row][column] === piece) {
                                pieceLocation = {row, column};
                                break;
                            }
                        }
                    }
                }
            } else {
                destination = {row: parseInt(move[2]) - 1, column: move.charCodeAt(1) - 97};
                for (let row = 0; row < cells.length; row++) {
                    for (let column = 0; column < cells[row].length; column++) {
                        if (cells[row][column] === piece) {
                            pieceLocation = {row, column};
                            break;
                        }
                    }
                }
            }

            //find the location of the piece in cells
            if (pieceLocation === undefined) {
                console.error(`Invalid move: ${move}. Piece was already taken.`);
                setMove('');
                return;
            }
            //set the piece location
            cells[pieceLocation.row][pieceLocation.column] = undefined;

            //set the destination
            cells[destination.row][destination.column] = piece;
            setCells(cells);
        } catch (e) {
            console.error(`Invalid move: ${move}. ${e}`);
            setMove('');
            return;
        }

        ws?.send(move);
        setMove('');
        focusMove();
    }

    return (
        <div className="App">
            <AppBar position='static'>
                <Toolbar sx={{
                    bgcolor: "white",
                    justifyContent: "space-between",
                }}>
                    <Box component={"div"} sx={{}}>
                        <Input
                            id={'move'}
                            placeholder="Move e.g. qe2"
                            disabled={ws === undefined}
                            value={move}
                            onChange={(event) => {
                                setMove(event.target.value);
                            }}
                            onKeyDown={(event) => {
                                if (event.code === 'Enter') {
                                    performMove();
                                }
                            }}
                        />
                        <Button onClick={connect} disabled={ws !== undefined}>Connect</Button>
                        <Button onClick={disconnect} disabled={ws === undefined}>Disconnect</Button>
                        <Button onClick={performMove} disabled={ws === undefined || move === ''}>Move</Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <main>

            <Paper sx={{
                display: "grid",
                gridTemplate: "repeat(10, min(9vw, 9vh)) / repeat(10, min(9vw, 9vh))",
                bgcolor: "rgba(0, 0, 0, 0.2)",
                border: "none",
            }} variant="outlined">
                <Paper sx={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    gridArea: "2 / 2 / span 8 / span 8",
                }} elevation={4}>
                {cells.map((cellRow, rowIndex) => {
                    return <Stack direction="row" sx={{
                        display: "flex",
                        flex: `1 1 min(7vw, 7vh)`,
                    }} key={rowIndex}>
                        {cellRow.map((cell, colIndex) => {
                                const isLight = isLightCell(rowIndex, colIndex);
                                return (
                                    <Paper key={cellKey(rowIndex, colIndex)}
                                           aria-label={cell ? `${cell?.color} ${cell?.name}` : "Empty"}
                                           sx={{
                                        flex: "1 1 min(9vw, 9vh)",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        bgcolor: isLight ? lightCellBackgroundColor : darkCellBackgroundColor,
                                        borderRadius: 0,
                                        fontSize: "4em",
                                    }} elevation={0}>
                                        <label aria-hidden>{cell?.char}</label>
                                    </Paper>
                                );
                            })
                        }
                    </Stack>
                })}
                </Paper>
            </Paper>
            </main>
            <footer></footer>
        </div>
    );
}

export default App;